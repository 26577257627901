export enum ValidationResult {
    none = 'none',
    failed = 'failed',
    passed = 'passed',
}

export const MIN_PASSWORD_LENGTH = 8;

export interface PasswordValidation {
    characterCount: ValidationResult;
    hasNumber: ValidationResult;
    hasSpecial: ValidationResult;
}

export function getPasswordValidations(password: string, showErrors: boolean = false): PasswordValidation {
    return {
        characterCount: minLength(password, MIN_PASSWORD_LENGTH, showErrors),
        hasNumber: containsNumbers(password, 1, showErrors),
        hasSpecial: containsSpecialCharacters(password, 1, showErrors),
    };
}

export function isValidPassword(password: string | null | undefined): boolean {
    if (!password) {
        return false;
    }
    const validations = getPasswordValidations(password, true);

    return Object.values(validations).every((v) => v === ValidationResult.passed);
}

export function minLength(input: string, min: number, showError: boolean = true): ValidationResult {
    if (input.length >= min) {
        return ValidationResult.passed;
    }
    return showError ? ValidationResult.failed : ValidationResult.none;
}

export function containsNumbers(
    input: string | null | undefined,
    min: number,
    showError: boolean = true
): ValidationResult {
    if (!input) {
        return showError ? ValidationResult.failed : ValidationResult.none;
    }
    if (input.replace(/[^0-9]/g, '').length >= min) {
        return ValidationResult.passed;
    }
    return showError ? ValidationResult.failed : ValidationResult.none;
}

export function containsSpecialCharacters(input: string, min: number, showError: boolean = true): ValidationResult {
    const specialCharacters = input.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/gi);

    if ((specialCharacters?.length ?? 0) >= min) {
        return ValidationResult.passed;
    }
    return showError ? ValidationResult.failed : ValidationResult.none;
}
